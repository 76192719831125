import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";

import googleImg from "../assets/google-logo.png";
import "../components/SignInWithGoogle.css";

import { auth, db } from "../config/firebase";
import { useNavigate } from "react-router-dom";
import { doc, getDoc, setDoc } from "firebase/firestore";

import axios from "axios";

// const GET_SUBSCRIPTION_URL =
//   "https://devapi.ez-byte.com:5050/groupadmin/api/v1/subscription/getSubscription";

const GET_SUBSCRIPTION_URL = process.env.REACT_APP_GET_SUBSCRIPTION_URL;

// export default function SignInWithGoogle() {
//   const navigate = useNavigate();

//   async function googleLogin() {
//     const provider = new GoogleAuthProvider();
//     try {
//       const result = await signInWithPopup(auth, provider);
//       const user = result.user;

//       const accessToken = await user.getIdToken(); // Fetch the token

//       console.log(`ACCESS TOKEN: ${accessToken}`);
//       if (user) {
//         const docRef = doc(db, "Users", user.uid);
//         const docSnap = await getDoc(docRef);

//         let userDetails;

//         if (docSnap.exists()) {
//           userDetails = docSnap.data();
//         } else {
//           userDetails = {
//             email: user.email,
//             name: user.displayName,
//             profilePicture: user.photoURL,
//           };

//           await setDoc(docRef, userDetails);
//         }

//         navigate("/home", {
//           state: {
//             userDetail: userDetails,
//             accessToken: accessToken,
//             authCode: 0,
//           },
//         });

//         // try {
//         //   const response = await axios.get(GET_SUBSCRIPTION_URL, {
//         //     headers: { Authorization: `Bearer ${accessToken}` },
//         //   });

//         //   if (response.data.code === 100) {
//         //     navigate("/home", { state: { authCode: 100 } });
//         //   } else if (response.data.code === 0) {
//         //     navigate("/home", {
//         //       state: {
//         //         userDetail: userDetails,
//         //         accessToken: accessToken,
//         //         authCode: 0,
//         //       },
//         //     });
//         //   } else {
//         //     navigate("/");
//         //   }
//         // } catch (apiError) {
//         //   console.error("Error fetching API response:", apiError);
//         //   navigate("/");
//         // }
//       }
//     } catch (error) {
//       console.error("Error during Google login:", error);
//     }
//   }

//   return (
//     <div className="google-login-container">
//       <p className="or-text">---or continue with---</p>
//       <button className="google-login-button" onClick={googleLogin}>
//         <img src={googleImg} alt="Google Logo" className="google-logo" />
//         <span className="google-login-text">Sign in with Google</span>
//       </button>
//     </div>
//   );
// }

export default function SignInWithGoogle() {
  const navigate = useNavigate();

  async function googleLogin() {
    const provider = new GoogleAuthProvider();
    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;

      const accessToken = await user.getIdToken(); // Fetch the token

      console.log(`ACCESS TOKEN: ${accessToken}`);
      if (user) {
        const docRef = doc(db, "Users", user.uid);
        const docSnap = await getDoc(docRef);

        let userDetails;

        if (docSnap.exists()) {
          userDetails = docSnap.data();
        } else {
          userDetails = {
            email: user.email,
            name: user.displayName,
            profilePicture: user.photoURL,
          };

          await setDoc(docRef, userDetails);
        }

        try {
          const response = await axios.get(GET_SUBSCRIPTION_URL, {
            headers: { Authorization: `Bearer ${accessToken}` },
          });

          if (response.data.code === 100) {
            navigate("/pricing", { state: { authCode: 100 } });
          } else if (response.data.code === 0) {
            navigate("/home", {
              state: {
                userDetail: userDetails,
                accessToken: accessToken,
                authCode: 0,
              },
            });
          } else {
            navigate("/");
          }
        } catch (apiError) {
          console.error("Error fetching API response:", apiError);
          navigate("/");
        }
      }
    } catch (error) {
      console.error("Error during Google login:", error);
    }
  }

  return (
    <div className="google-login-container">
      <p className="or-text">---or continue with---</p>
      <button className="google-login-button" onClick={googleLogin}>
        <img src={googleImg} alt="Google Logo" className="google-logo" />
        <span className="google-login-text">Sign in with Google</span>
      </button>
    </div>
  );
}
