/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useRef, useState } from "react";
// import { COMPANY_LOGO } from "../APP_LOGO/app_logo";
// import COMPANY_LOGO from "../../assets/EZ-BYTE-BLUE.png";
// import COMPANY_LOGO from "../../assets/e10.png";
import { COMPANY_LOGO_RECTANGLE } from "../../components/APP_LOGO/app_logo";

import EZECOVER from "../../assets/ezecover.jpg";
import "../LandingPage/LandingScreen.css";

import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

import axios from "axios";

import { loadStripe } from "@stripe/stripe-js";
import { Alert, Snackbar } from "@mui/material";

export const LOGIN_URL = process.env.REACT_APP_LOGIN_URL;

export default function LandingScreen() {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isAnnual, setIsAnnual] = useState(false);
  const [isAnnualBusiness, setIsAnnualBusiness] = useState(false);
  const [isTopAreaVisible, setIsTopAreaVisible] = useState(true);

  const [showOverlay, setShowOverlay] = useState(false);

  const [pricingData, setPricingData] = useState([]);
  const [pricingDataForBusiness, setPricingDataFoBusiness] = useState([]);

  const [selectedPlan, setSelectedPlan] = useState(null);
  const [dataToSendToStripe, setDataToSendToStripe] = useState([]);

  const navigate = useNavigate();

  const hasNavigated = useRef(false);

  const [searchParams] = useSearchParams();
  const [showMessage, setShowMessage] = useState(false);
  const isIdAvailable = searchParams.get("session_id");

  useEffect(() => {
    if (isIdAvailable) {
      setShowMessage(true);

      setTimeout(() => setShowMessage(false), 5000);
    }
  }, [isIdAvailable]);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 0);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const fetchPricingData = async () => {
      try {
        const responseData = await axios.get("/pricingData.json");
        const myData = responseData.data;

        setPricingData(myData);
      } catch (e) {
        console.log(e);
      }
    };
    fetchPricingData();
  }, []);

  // useEffect(() => {
  //   const fetchPricingDataBusiness = async () => {
  //     try {
  //       const responseData = await axios.get("/pricingDataBusiness.json");
  //       const myData = responseData.data;

  //       setPricingDataFoBusiness(myData);
  //     } catch (e) {
  //       console.log(e);
  //     }
  //   };
  //   fetchPricingDataBusiness();
  // }, []);

  const scrollToPricing = () => {
    const pricingSection = document.getElementById("pricing-section");
    pricingSection.scrollIntoView({ behavior: "smooth" });
  };

  const togglePricing = () => {
    setIsAnnual((prev) => !prev);
  };

  const togglePricingBusiness = () => {
    setIsAnnualBusiness((prev) => !prev);
  };

  const navigationHandeler = () => {
    // navigate(`${LOGIN_URL}/login`);
    navigate(`/login`);
  };

  const handleLogoClick = () => {
    navigate("/");
  };

  const handleSubscribe = (plan, isAnnual) => {
    const subscriptionDetails = {
      planName: plan.plan,
      price: isAnnual ? plan.annual : plan.monthly,
      priceingType: isAnnual ? "Anually" : "Monthly",
      description: plan.features,
    };

    setSelectedPlan(subscriptionDetails);
    setDataToSendToStripe([...dataToSendToStripe, subscriptionDetails]);
    setShowOverlay(true);

    console.log(subscriptionDetails);

    navigate("/login", { state: { from: "landing" } });
  };

  const handleCloseOverlay = () => {
    setShowOverlay(false);
  };

  return (
    <>
      <Snackbar
        open={showMessage}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          severity="success"
          variant="filled"
          sx={{
            width: "100%",
            backgroundColor: "#59E659",
            letterSpacing: "1px",
            marginTop: "8px",
          }}
        >
          Please signin to get started
        </Alert>
      </Snackbar>

      <div className="landing-container">
        {isTopAreaVisible && (
          <div className="show-ad">
            Grab the latest deal at unbeatable price.
            {/* <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  scrollToPricing();
                }}
              >
                Learn more
              </a> */}
          </div>
        )}
        <header
          style={{ top: isTopAreaVisible ? "30px" : "0" }}
          className={`landing-header ${isScrolled ? "scrolled" : ""}`}
        >
          <img
            src={COMPANY_LOGO_RECTANGLE}
            alt="Logo"
            className="app-logo"
            onClick={handleLogoClick}
            n
            style={{
              cursor: "pointer",
            }}
          />

          <nav className="landing-nav-bar">
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                scrollToPricing();
              }}
            >
              Pricing
            </a>
            {/* <a href="/login">Login</a> */}

            <button className="login" onClick={navigationHandeler}>
              Login
            </button>
          </nav>
        </header>

        <div className="scrollable-content">
          <div className="main-content">
            {/* <img
                src="https://ctzeusbackenduatlogo.blob.core.windows.net/uatlogo/ez-byte-landing-page-background.jpg"
                alt="ezebyte"
                className="background-video"
              /> */}
            <img src={EZECOVER} alt="ezebyte" className="background-video" />
            {/* <video
                src={myImage}
                autoPlay
                loop
                muted
                // className="background-video"
              /> */}
            <span className="photo-title">
              <div>Cloud Backup.</div>
              <div>Photos.</div>
              <div>Videos.</div>
              <div>Files.</div>
              <div>Digital Assets.</div>
            </span>
          </div>

          {pricingData.length !== 0 && (
            <div id="pricing-section" className="container">
              <h2 className="plans-heading">Home Plans</h2>

              <div className="toggle-container">
                <span
                  style={
                    {
                      // marginLeft: "45px",
                    }
                  }
                  // className={!isAnnual ? "active leading-square" : ""}
                  className={`leading-square ${!isAnnual ? `active` : ``}`}
                >
                  Monthly
                </span>
                <div className="toggle-switch" onClick={togglePricing}>
                  <div
                    className={`toggle-knob ${isAnnual ? "annual" : "monthly"}`}
                  />
                </div>
                <span className={isAnnual ? "active" : ""}>Yearly</span>
              </div>

              <div className="price-row">
                {pricingData.map((item, index) => (
                  <div key={index} className="price-col">
                    <p className="plan-title">{item.plan}</p>
                    <h3>
                      {isAnnual ? item.annual : item.monthly}
                      {/* <span>{isAnnual ? "/ year" : "/ month"}</span> */}

                      <span className="monthly-yearly">
                        {item.isBusiness
                          ? `/ user ${isAnnual ? "/ year" : "/ month"}`
                          : isAnnual
                          ? "/ year"
                          : "/ month"}
                      </span>
                    </h3>
                    <button onClick={() => handleSubscribe(item, isAnnual)}>
                      Subscribe
                    </button>
                    <ul>
                      {item.features.map((feature, i) => (
                        <li key={i}>{feature}</li>
                      ))}
                      {item.features.length < 4 && <li>&nbsp;</li>}
                    </ul>
                    {/* <button>Subscribe</button> */}
                  </div>
                ))}
              </div>
            </div>
          )}

          {pricingDataForBusiness.length !== 0 && (
            <div id="pricing-section" className="container">
              <h2 className="plans-heading">Business Plans</h2>

              <div className="toggle-container">
                <span
                  className={`leading-square ${
                    !isAnnualBusiness ? `active` : ``
                  }`}
                >
                  Monthly
                </span>
                <div className="toggle-switch" onClick={togglePricingBusiness}>
                  <div
                    className={`toggle-knob ${
                      isAnnualBusiness ? "annual" : "monthly"
                    }`}
                  />
                </div>
                <span className={isAnnualBusiness ? "active" : ""}>Yearly</span>
              </div>

              <div className="price-row">
                {pricingDataForBusiness.map((item, index) => (
                  <div key={index} className="price-col">
                    <p className="plan-title">{item.plan}</p>
                    <h3>
                      {isAnnualBusiness ? item.annual : item.monthly}
                      {/* <span>{isAnnual ? "/ year" : "/ month"}</span> */}

                      <span className="monthly-yearly">
                        {item.isBusiness
                          ? `/ user ${isAnnualBusiness ? "/ year" : "/ month"}`
                          : isAnnualBusiness
                          ? "/ year"
                          : "/ month"}
                      </span>
                    </h3>
                    <button onClick={() => handleSubscribe(item, isAnnual)}>
                      Subscribe
                    </button>
                    <ul>
                      {item.features.map((feature, i) => (
                        <li key={i}>{feature}</li>
                      ))}
                      {item.features.length < 4 && <li>&nbsp;</li>}
                    </ul>
                  </div>
                ))}
              </div>
            </div>
          )}

          {/* <div className="empty-container"></div> */}
          <footer className="footer">
            <div className="line"></div>
            <div className="footer-container">
              <div className="footer-row">
                <div className="footer-col two">
                  <h4>Solutions</h4>
                  <ul>
                    <li>
                      <a href="https://ezebyte.com/solutions/#vendor">
                        Vendor Integration
                      </a>
                    </li>
                    <li>{/* <a href="#">Locations</a> */}</li>
                    <li>
                      <a href="https://ezebyte.com/solutions/#erp">
                        ERP Integration
                      </a>
                    </li>
                  </ul>
                </div>

                <div className="footer-col one " style={{ marginTop: "-2px" }}>
                  <h4>Products</h4>
                  <ul>
                    <li>
                      <a href="https://ezebyte.com/products/#fraudguard">
                        Fraud Guard
                      </a>
                    </li>
                    <li>
                      <a href="https://ezebyte.com/products/#ez-drive">
                        EZ-Drive
                      </a>
                    </li>
                    <li>
                      <a href="https://ezebyte.com/products/#ez-share">
                        EZ-Share
                      </a>
                    </li>
                    <li>
                      <a href="https://ezebyte.com/products/#ez-eye">EZ-Eye</a>
                    </li>
                    <li>
                      <a href="https://ezebyte.com/products/#ez-schedule">
                        EZ-Schedule
                      </a>
                    </li>
                    <li>
                      <a href="https://ezebyte.com/products/#ez-flow">
                        EZ-Flow
                      </a>
                    </li>
                    <li>
                      <a href="https://ezebyte.com/products/#ez-dash">
                        EZ-Dash
                      </a>
                    </li>
                  </ul>
                </div>

                <div className="footer-col one">
                  <h4>Get Help</h4>
                  <ul>
                    <li>
                      <a href="#">Support</a>
                    </li>
                    <li>
                      <a href="#">Return Policy</a>
                    </li>
                    <li>
                      <a href="#">Privacy</a>
                    </li>
                    <li>
                      <a href="#">Terms & Conditions</a>
                    </li>
                  </ul>
                </div>

                <div className="footer-col">
                  <h4>Company</h4>
                  <ul>
                    <li>
                      <a href="https://ezebyte.com/aboutus/">About Us</a>
                    </li>
                    <li>
                      <a href="https://ezebyte.com/#contact">Contact</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="footer-bottom">
              <p>&copy; 2025 EZ-BYTE. All rights reserved.</p>
            </div>
          </footer>
        </div>
      </div>
    </>
  );
}

// ---------------------------- OLD CODE ---------------------------------------
// export default function LandingScreen() {
//   const [isScrolled, setIsScrolled] = useState(false);
//   const [isAnnual, setIsAnnual] = useState(false);
//   const [isAnnualBusiness, setIsAnnualBusiness] = useState(false);
//   const [isTopAreaVisible, setIsTopAreaVisible] = useState(true);

//   const [showOverlay, setShowOverlay] = useState(false);

//   const [pricingData, setPricingData] = useState([]);
//   const [pricingDataForBusiness, setPricingDataFoBusiness] = useState([]);

//   const [selectedPlan, setSelectedPlan] = useState(null);
//   const [dataToSendToStripe, setDataToSendToStripe] = useState([]);

//   const navigate = useNavigate();

//   const hasNavigated = useRef(false);

//   // ------------------------IMPORTANT------------------------

//   useEffect(() => {
//     const activate = async () => {
//       try {
//         const firebaseSessionData = sessionStorage.getItem(
//           `firebase:authUser:AIzaSyBUmtANkLfbFIrRLg8fAV_FhiwQCmZzVgM:[DEFAULT]`
//         );

//         if (!firebaseSessionData) {
//           throw new Error(
//             "Authentication token not found. Please log in again."
//           );
//         }

//         const parsedData = JSON.parse(firebaseSessionData);
//         const token = parsedData?.stsTokenManager?.accessToken; // Extract token

//         if (!token) {
//           throw new Error("Failed to retrieve Firebase token.");
//         }

//         console.log(`----TOKEN-------`);
//         console.log("");
//         console.log(parsedData);
//         console.log(token);
//         console.log(parsedData?.email);
//         console.log(parsedData?.displayName);
//         console.log(parsedData?.photoURL);
//         console.log("");
//         console.log(`----TOKEN-------`);

//         // const searchParams = new URLSearchParams(location.search);
//         // const id = searchParams.get("session_id");
//         const id = "";

//         console.log(`SESSION ID`);
//         console.log(id);
//         console.log(`SESSION ID`);

//         if (!id) {
//           // setError("Invalid or missing ID in URL");
//           console.log("Invalid or missing ID in URL");
//           // setLoading(false);

//           return;
//         }

//         const headers = {
//           "Content-Type": "application/json",
//           Authorization: `Bearer ${token}`,
//         };

//         const payload = {
//           sessionId: id,
//         };

//         const response = await axios.post(
//           ACTIVATE_SUBSCRIPTION,
//           {
//             sessionId: id,
//           },
//           {
//             headers,
//           }
//         );

//         if (response.data.code === 0) {
//           // navigate("/home", {
//           //   userDetail: {
//           //     email: parsedData.email,
//           //     name: parsedData.displayName,
//           //     profilePicture: parsedData.photoURL,
//           //   },
//           //   accessToken: token,
//           //   authCode: 0,
//           // });

//           hasNavigated.current = true;
//           navigate("/home", {
//             state: {
//               userDetail: {
//                 email: parsedData.email,
//                 name: parsedData.displayName,
//                 profilePicture: parsedData.photoURL,
//               },
//               accessToken: token,
//               authCode: 0,
//             },
//           });

//           // navigate("/home");

//           console.log(response);
//           console.log(response.data.code);
//           console.log(response.data.data);
//         } else {
//           // navigate("/");
//           console.log(response.data.message || "Payment validation failed");
//         }
//       } catch (error) {
//         // navigate("/");
//         console.log(`Error getting In the Activate Subcription`);
//         console.log(error);
//         console.log(`Error getting In the Activate Subcription`);
//       }
//     };

//     activate();
//   }, []);

//   // useEffect(() => {
//   //   // const searchParams = new URLSearchParams(location.search);
//   //   // const id = searchParams.get("session_id");

//   //   // console.log(`SESSION ID`);
//   //   // console.log(id);
//   //   // console.log(`SESSION ID`);

//   //   // if (!id) {
//   //   //   setError("Invalid or missing ID in URL");
//   //   //   console.log("Invalid or missing ID in URL");
//   //   //   setLoading(false);

//   //   //   return;
//   //   // }

//   //   const activate = async () => {
//   //     try {
//   //       const firebaseSessionData = sessionStorage.getItem(
//   //         `firebase:authUser:AIzaSyBUmtANkLfbFIrRLg8fAV_FhiwQCmZzVgM:[DEFAULT]`
//   //       );

//   //       if (!firebaseSessionData) {
//   //         throw new Error(
//   //           "Authentication token not found. Please log in again."
//   //         );
//   //       }

//   //       const parsedData = JSON.parse(firebaseSessionData);
//   //       const token = parsedData?.stsTokenManager?.accessToken; // Extract token

//   //       if (!token) {
//   //         throw new Error("Failed to retrieve Firebase token.");
//   //       }

//   //       console.log(`----TOKEN-------`);
//   //       console.log("");
//   //       console.log(parsedData);
//   //       console.log(token);
//   //       console.log(parsedData?.email);
//   //       console.log(parsedData?.displayName);
//   //       console.log(parsedData?.photoURL);
//   //       console.log("");
//   //       console.log(`----TOKEN-------`);

//   //       // const searchParams = new URLSearchParams(location.search);
//   //       // const id = searchParams.get("session_id");
//   //       const id = "";

//   //       console.log(`SESSION ID`);
//   //       console.log(id);
//   //       console.log(`SESSION ID`);

//   //       if (!id) {
//   //         // setError("Invalid or missing ID in URL");
//   //         console.log("Invalid or missing ID in URL");
//   //         // setLoading(false);

//   //         return;
//   //       }

//   //       const headers = {
//   //         "Content-Type": "application/json",
//   //         Authorization: `Bearer ${token}`,
//   //       };

//   //       const payload = {
//   //         sessionId: id,
//   //       };

//   //       const response = await axios.post(
//   //         ACTIVATE_SUBSCRIPTION,
//   //         {
//   //           sessionId: id,
//   //         },
//   //         {
//   //           headers,
//   //         }
//   //       );

//   //       if (response.data.code === 0) {
//   //         // navigate("/home", {
//   //         //   userDetail: {
//   //         //     email: parsedData.email,
//   //         //     name: parsedData.displayName,
//   //         //     profilePicture: parsedData.photoURL,
//   //         //   },
//   //         //   accessToken: token,
//   //         //   authCode: 0,
//   //         // });

//   //         hasNavigated.current = true;
//   //         navigate("/home", {
//   //           state: {
//   //             userDetail: {
//   //               email: parsedData.email,
//   //               name: parsedData.displayName,
//   //               profilePicture: parsedData.photoURL,
//   //             },
//   //             accessToken: token,
//   //             authCode: 0,
//   //           },
//   //         });

//   //         // navigate("/home");

//   //         console.log(response);
//   //         console.log(response.data.code);
//   //         console.log(response.data.data);
//   //       } else {
//   //         // navigate("/");
//   //         console.log(response.data.message || "Payment validation failed");
//   //       }
//   //     } catch (error) {
//   //       // navigate("/");
//   //       console.log(`Error getting In the Activate Subcription`);
//   //       console.log(error);
//   //       console.log(`Error getting In the Activate Subcription`);
//   //     }
//   //   };

//   //   activate();
//   // }, []);

//   // ------------------------IMPORTANT------------------------

//   useEffect(() => {
//     const handleScroll = () => {
//       setIsScrolled(window.scrollY > 0);
//     };

//     window.addEventListener("scroll", handleScroll);

//     return () => {
//       window.removeEventListener("scroll", handleScroll);
//     };
//   }, []);

//   useEffect(() => {
//     const fetchPricingData = async () => {
//       try {
//         const responseData = await axios.get("/pricingData.json");
//         const myData = responseData.data;

//         setPricingData(myData);
//       } catch (e) {
//         console.log(e);
//       }
//     };
//     fetchPricingData();
//   }, []);

//   useEffect(() => {
//     const fetchPricingDataBusiness = async () => {
//       try {
//         const responseData = await axios.get("/pricingDataBusiness.json");
//         const myData = responseData.data;

//         setPricingDataFoBusiness(myData);
//       } catch (e) {
//         console.log(e);
//       }
//     };
//     fetchPricingDataBusiness();
//   }, []);

//   const scrollToPricing = () => {
//     const pricingSection = document.getElementById("pricing-section");
//     pricingSection.scrollIntoView({ behavior: "smooth" });
//   };

//   const togglePricing = () => {
//     setIsAnnual((prev) => !prev);
//   };

//   const togglePricingBusiness = () => {
//     setIsAnnualBusiness((prev) => !prev);
//   };

//   const navigationHandeler = () => {
//     // navigate(`${LOGIN_URL}/login`);
//     navigate(`/login`);
//   };

//   const handleLogoClick = () => {
//     navigate("/");
//   };

//   const handleSubscribe = (plan, isAnnual) => {
//     const subscriptionDetails = {
//       planName: plan.plan,
//       price: isAnnual ? plan.annual : plan.monthly,
//       priceingType: isAnnual ? "Anually" : "Monthly",
//       description: plan.features,
//     };

//     setSelectedPlan(subscriptionDetails);
//     setDataToSendToStripe([...dataToSendToStripe, subscriptionDetails]);
//     setShowOverlay(true);

//     console.log(subscriptionDetails);
//   };

//   const handleCloseOverlay = () => {
//     setShowOverlay(false);
//   };

//   return (
//     <div className="landing-container">
//       {isTopAreaVisible && (
//         <div className="show-ad">
//           Grab the latest deal at unbeatable price.
//           {/* <a
//               href="#"
//               onClick={(e) => {
//                 e.preventDefault();
//                 scrollToPricing();
//               }}
//             >
//               Learn more
//             </a> */}
//         </div>
//       )}
//       <header
//         style={{ top: isTopAreaVisible ? "30px" : "0" }}
//         className={`landing-header ${isScrolled ? "scrolled" : ""}`}
//       >
//         {/* <img
//             src="https://ctzeusbackenduatlogo.blob.core.windows.net/uatlogo/ez-byte-logo.png"
//             alt="Logo"
//             className="app-logo"
//             onClick={handleLogoClick}
//             n
//             style={{
//               cursor: "pointer",
//             }}
//           /> */}

//         <img
//           src={COMPANY_LOGO_RECTANGLE}
//           alt="Logo"
//           className="app-logo"
//           onClick={handleLogoClick}
//           n
//           style={{
//             cursor: "pointer",
//           }}
//         />

//         <nav className="landing-nav-bar">
//           <a
//             href="#"
//             onClick={(e) => {
//               e.preventDefault();
//               scrollToPricing();
//             }}
//           >
//             Pricing
//           </a>
//           {/* <a href="/login">Login</a> */}

//           <button className="login" onClick={navigationHandeler}>
//             Login
//           </button>
//         </nav>
//       </header>

//       <div className="scrollable-content">
//         <div className="main-content">
//           {/* <img
//               src="https://ctzeusbackenduatlogo.blob.core.windows.net/uatlogo/ez-byte-landing-page-background.jpg"
//               alt="ezebyte"
//               className="background-video"
//             /> */}
//           <img src={EZECOVER} alt="ezebyte" className="background-video" />
//           {/* <video
//               src={myImage}
//               autoPlay
//               loop
//               muted
//               // className="background-video"
//             /> */}
//           <span className="photo-title">
//             <div>Cloud Backup.</div>
//             <div>Photos.</div>
//             <div>Videos.</div>
//             <div>Files.</div>
//             <div>Digital Assets.</div>
//           </span>
//         </div>

//         {pricingData.length !== 0 && (
//           <div id="pricing-section" className="container">
//             <h2 className="plans-heading">Home Plans</h2>

//             <div className="toggle-container">
//               <span
//                 style={
//                   {
//                     // marginLeft: "45px",
//                   }
//                 }
//                 // className={!isAnnual ? "active leading-square" : ""}
//                 className={`leading-square ${!isAnnual ? `active` : ``}`}
//               >
//                 Monthly
//               </span>
//               <div className="toggle-switch" onClick={togglePricing}>
//                 <div
//                   className={`toggle-knob ${isAnnual ? "annual" : "monthly"}`}
//                 />
//               </div>
//               <span className={isAnnual ? "active" : ""}>Yearly</span>
//             </div>

//             <div className="price-row">
//               {pricingData.map((item, index) => (
//                 <div key={index} className="price-col">
//                   <p className="plan-title">{item.plan}</p>
//                   <h3>
//                     {isAnnual ? item.annual : item.monthly}
//                     {/* <span>{isAnnual ? "/ year" : "/ month"}</span> */}

//                     <span className="monthly-yearly">
//                       {item.isBusiness
//                         ? `/ user ${isAnnual ? "/ year" : "/ month"}`
//                         : isAnnual
//                         ? "/ year"
//                         : "/ month"}
//                     </span>
//                   </h3>
//                   <button onClick={() => handleSubscribe(item, isAnnual)}>
//                     Subscribe
//                   </button>
//                   <ul>
//                     {item.features.map((feature, i) => (
//                       <li key={i}>{feature}</li>
//                     ))}
//                     {item.features.length < 4 && <li>&nbsp;</li>}
//                   </ul>
//                   {/* <button>Subscribe</button> */}
//                 </div>
//               ))}
//             </div>
//           </div>
//         )}

//         {pricingDataForBusiness.length !== 0 && (
//           <div id="pricing-section" className="container">
//             <h2 className="plans-heading">Business Plans</h2>

//             <div className="toggle-container">
//               <span
//                 className={`leading-square ${
//                   !isAnnualBusiness ? `active` : ``
//                 }`}
//               >
//                 Monthly
//               </span>
//               <div className="toggle-switch" onClick={togglePricingBusiness}>
//                 <div
//                   className={`toggle-knob ${
//                     isAnnualBusiness ? "annual" : "monthly"
//                   }`}
//                 />
//               </div>
//               <span className={isAnnualBusiness ? "active" : ""}>Yearly</span>
//             </div>

//             <div className="price-row">
//               {pricingDataForBusiness.map((item, index) => (
//                 <div key={index} className="price-col">
//                   <p className="plan-title">{item.plan}</p>
//                   <h3>
//                     {isAnnualBusiness ? item.annual : item.monthly}
//                     {/* <span>{isAnnual ? "/ year" : "/ month"}</span> */}

//                     <span className="monthly-yearly">
//                       {item.isBusiness
//                         ? `/ user ${isAnnualBusiness ? "/ year" : "/ month"}`
//                         : isAnnualBusiness
//                         ? "/ year"
//                         : "/ month"}
//                     </span>
//                   </h3>
//                   <button onClick={() => handleSubscribe(item, isAnnual)}>
//                     Subscribe
//                   </button>
//                   <ul>
//                     {item.features.map((feature, i) => (
//                       <li key={i}>{feature}</li>
//                     ))}
//                     {item.features.length < 4 && <li>&nbsp;</li>}
//                   </ul>
//                 </div>
//               ))}
//             </div>
//           </div>
//         )}

//         {/* <div className="empty-container"></div> */}
//         <footer className="footer">
//           <div className="line"></div>
//           <div className="footer-container">
//             <div className="footer-row">
//               <div className="footer-col two">
//                 <h4>Solutions</h4>
//                 <ul>
//                   <li>
//                     <a href="https://ezebyte.com/solutions/#vendor">
//                       Vendor Integration
//                     </a>
//                   </li>
//                   <li>{/* <a href="#">Locations</a> */}</li>
//                   <li>
//                     <a href="https://ezebyte.com/solutions/#erp">
//                       ERP Integration
//                     </a>
//                   </li>
//                 </ul>
//               </div>

//               <div className="footer-col one " style={{ marginTop: "-2px" }}>
//                 <h4>Products</h4>
//                 <ul>
//                   <li>
//                     <a href="https://ezebyte.com/products/#fraudguard">
//                       Fraud Guard
//                     </a>
//                   </li>
//                   <li>
//                     <a href="https://ezebyte.com/products/#ez-drive">
//                       EZ-Drive
//                     </a>
//                   </li>
//                   <li>
//                     <a href="https://ezebyte.com/products/#ez-share">
//                       EZ-Share
//                     </a>
//                   </li>
//                   <li>
//                     <a href="https://ezebyte.com/products/#ez-eye">EZ-Eye</a>
//                   </li>
//                   <li>
//                     <a href="https://ezebyte.com/products/#ez-schedule">
//                       EZ-Schedule
//                     </a>
//                   </li>
//                   <li>
//                     <a href="https://ezebyte.com/products/#ez-flow">EZ-Flow</a>
//                   </li>
//                   <li>
//                     <a href="https://ezebyte.com/products/#ez-dash">EZ-Dash</a>
//                   </li>
//                 </ul>
//               </div>

//               <div className="footer-col one">
//                 <h4>Get Help</h4>
//                 <ul>
//                   <li>
//                     <a href="#">Support</a>
//                   </li>
//                   <li>
//                     <a href="#">Return Policy</a>
//                   </li>
//                   <li>
//                     <a href="#">Privacy</a>
//                   </li>
//                   <li>
//                     <a href="#">Terms & Conditions</a>
//                   </li>
//                 </ul>
//               </div>

//               <div className="footer-col">
//                 <h4>Company</h4>
//                 <ul>
//                   <li>
//                     <a href="https://ezebyte.com/aboutus/">About Us</a>
//                   </li>
//                   <li>
//                     <a href="https://ezebyte.com/#contact">Contact</a>
//                   </li>
//                 </ul>
//               </div>
//             </div>
//           </div>

//           <div className="footer-bottom">
//             <p>&copy; 2025 EZ-BYTE. All rights reserved.</p>
//           </div>
//         </footer>
//       </div>
//       {showOverlay && (
//         <CheckoutOverlay
//           selectedPlan={selectedPlan}
//           onClose={handleCloseOverlay}
//           dataToSendToStripe={dataToSendToStripe}
//         />
//       )}
//     </div>
//   );
// }

// const CheckoutOverlay = ({ selectedPlan, onClose, dataToSendToStripe }) => {
//   console.log(dataToSendToStripe);

//   const tax = 2.65;
//   const totalAmount = calculateTotalAmount(tax, selectedPlan.price);

//   function calculateTotalAmount(Tax, Price) {
//     const TaxAmount = parseFloat(Tax);
//     const PriceAmount = parseFloat(Price.replace("$", ""));
//     return (TaxAmount + PriceAmount).toFixed(2);
//   }

//   const overlayOutsideClickHandler = (e) => {
//     if (e.target.classList.contains("checkout-overlay")) {
//       onClose();
//     }
//   };

//   const paymentHandler = async () => {
//     const stripe = await loadStripe(
//       "pk_test_51QYRrwP4VPRr08MivQkpNa9EAIEf8Jn4zUvB3H1yM7s8EQUeYmUA28mDwRmNP85b08LQb00OhqIaA5ovPKOlgUif00KWKeDTmE"
//     );

//     const body = {
//       products: dataToSendToStripe,
//     };

//     const headers = {
//       "Content-Type": "application/json",
//     };
//   };

//   return (
//     <div className="checkout-overlay" onClick={overlayOutsideClickHandler}>
//       <div className="checkout-overlay-content">
//         <h2>Checkout</h2>
//         <h3>Purschase Details</h3>

//         <div className="checkout-container">
//           <div className="checkout-summary">
//             <p>
//               <span className="label">
//                 <strong>Plan</strong>:
//               </span>
//               <span className="value">{selectedPlan.planName}</span>
//             </p>
//             <p>
//               <span className="label">
//                 <strong>Tax</strong>:
//               </span>
//               <span className="value">{`$${tax}`}</span>
//             </p>

//             <p>
//               <span className="label">
//                 <strong>Price</strong>:
//               </span>
//               <span className="value">{selectedPlan.price}</span>
//             </p>
//             <div className="total-bill">
//               <p>
//                 <span className="label">
//                   <strong>Amount Payable</strong>:
//                 </span>
//                 <span className="value">{`$${totalAmount}`}</span>
//               </p>
//             </div>
//           </div>

//           <div className="price-col-checkout">
//             <h3>{selectedPlan.planName}</h3>
//             {/* <p>
//                 <strong>Price:</strong> {selectedPlan.price}
//               </p> */}
//             <ul>
//               {selectedPlan.description.map((feature, index) => (
//                 <li key={index}>{feature}</li>
//               ))}
//             </ul>
//           </div>
//         </div>
//         <div className="confirm-button">
//           {/* <button className="cancel-button" onClick={onClose}>
//               Cancel
//             </button> */}
//           <button onClick={paymentHandler}>{`Pay $${totalAmount}`}</button>
//         </div>
//       </div>
//     </div>
//   );
// };

// -------------------------------------------------------

// export default function LandingScreen() {
//   const [isScrolled, setIsScrolled] = useState(false);
//   const [isAnnual, setIsAnnual] = useState(false);
//   const [isAnnualBusiness, setIsAnnualBusiness] = useState(false);
//   const [isTopAreaVisible, setIsTopAreaVisible] = useState(true);

//   const [showOverlay, setShowOverlay] = useState(false);

//   const [pricingData, setPricingData] = useState([]);
//   const [pricingDataForBusiness, setPricingDataFoBusiness] = useState([]);

//   const [selectedPlan, setSelectedPlan] = useState(null);
//   const [dataToSendToStripe, setDataToSendToStripe] = useState([]);

//   const navigate = useNavigate();

//   const hasNavigated = useRef(false);

//   // ------------------------IMPORTANT------------------------

//   const location = useLocation();
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);

//   useEffect(() => {
//     // const searchParams = new URLSearchParams(location.search);
//     // const id = searchParams.get("session_id");

//     // console.log(`SESSION ID`);
//     // console.log(id);
//     // console.log(`SESSION ID`);

//     // if (!id) {
//     //   setError("Invalid or missing ID in URL");
//     //   console.log("Invalid or missing ID in URL");
//     //   setLoading(false);

//     //   return;
//     // }

//     const activate = async () => {
//       try {
//         const firebaseSessionData = sessionStorage.getItem(
//           `firebase:authUser:AIzaSyBUmtANkLfbFIrRLg8fAV_FhiwQCmZzVgM:[DEFAULT]`
//         );

//         if (!firebaseSessionData) {
//           throw new Error(
//             "Authentication token not found. Please log in again."
//           );
//         }

//         const parsedData = JSON.parse(firebaseSessionData);
//         const token = parsedData?.stsTokenManager?.accessToken; // Extract token

//         if (!token) {
//           throw new Error("Failed to retrieve Firebase token.");
//         }

//         console.log(`----TOKEN-------`);
//         console.log("");
//         console.log(parsedData);
//         console.log(token);
//         console.log(parsedData?.email);
//         console.log(parsedData?.displayName);
//         console.log(parsedData?.photoURL);
//         console.log("");
//         console.log(`----TOKEN-------`);

//         // const searchParams = new URLSearchParams(location.search);
//         // const id = searchParams.get("session_id");
//         const id = "";

//         console.log(`SESSION ID`);
//         console.log(id);
//         console.log(`SESSION ID`);

//         if (!id) {
//           // setError("Invalid or missing ID in URL");
//           console.log("Invalid or missing ID in URL");
//           // setLoading(false);

//           return;
//         }

//         const headers = {
//           "Content-Type": "application/json",
//           Authorization: `Bearer ${token}`,
//         };

//         const payload = {
//           sessionId: id,
//         };

//         const response = await axios.post(
//           ACTIVATE_SUBSCRIPTION,
//           {
//             sessionId: id,
//           },
//           {
//             headers,
//           }
//         );

//         if (response.data.code === 0) {
//           // navigate("/home", {
//           //   userDetail: {
//           //     email: parsedData.email,
//           //     name: parsedData.displayName,
//           //     profilePicture: parsedData.photoURL,
//           //   },
//           //   accessToken: token,
//           //   authCode: 0,
//           // });

//           hasNavigated.current = true;
//           navigate("/home", {
//             state: {
//               userDetail: {
//                 email: parsedData.email,
//                 name: parsedData.displayName,
//                 profilePicture: parsedData.photoURL,
//               },
//               accessToken: token,
//               authCode: 0,
//             },
//           });

//           // navigate("/home");

//           console.log(response);
//           console.log(response.data.code);
//           console.log(response.data.data);
//         } else {
//           // navigate("/");
//           console.log(response.data.message || "Payment validation failed");
//         }
//       } catch (error) {
//         // navigate("/");
//         console.log(`Error getting In the Activate Subcription`);
//         console.log(error);
//         console.log(`Error getting In the Activate Subcription`);
//       }
//     };

//     activate();
//   }, []);

//   // ------------------------IMPORTANT------------------------

//   useEffect(() => {
//     const handleScroll = () => {
//       setIsScrolled(window.scrollY > 0);
//     };

//     window.addEventListener("scroll", handleScroll);

//     return () => {
//       window.removeEventListener("scroll", handleScroll);
//     };
//   }, []);

//   useEffect(() => {
//     const fetchPricingData = async () => {
//       try {
//         const responseData = await axios.get("/pricingData.json");
//         const myData = responseData.data;

//         setPricingData(myData);
//       } catch (e) {
//         console.log(e);
//       }
//     };
//     fetchPricingData();
//   }, []);

//   useEffect(() => {
//     const fetchPricingDataBusiness = async () => {
//       try {
//         const responseData = await axios.get("/pricingDataBusiness.json");
//         const myData = responseData.data;

//         setPricingDataFoBusiness(myData);
//       } catch (e) {
//         console.log(e);
//       }
//     };
//     fetchPricingDataBusiness();
//   }, []);

//   const scrollToPricing = () => {
//     const pricingSection = document.getElementById("pricing-section");
//     pricingSection.scrollIntoView({ behavior: "smooth" });
//   };

//   const togglePricing = () => {
//     setIsAnnual((prev) => !prev);
//   };

//   const togglePricingBusiness = () => {
//     setIsAnnualBusiness((prev) => !prev);
//   };

//   const navigationHandeler = () => {
//     // navigate(`${LOGIN_URL}/login`);
//     navigate(`/login`);
//   };

//   const handleLogoClick = () => {
//     navigate("/");
//   };

//   const handleSubscribe = (plan, isAnnual) => {
//     const subscriptionDetails = {
//       planName: plan.plan,
//       price: isAnnual ? plan.annual : plan.monthly,
//       priceingType: isAnnual ? "Anually" : "Monthly",
//       description: plan.features,
//     };

//     setSelectedPlan(subscriptionDetails);
//     setDataToSendToStripe([...dataToSendToStripe, subscriptionDetails]);
//     setShowOverlay(true);

//     console.log(subscriptionDetails);
//   };

//   const handleCloseOverlay = () => {
//     setShowOverlay(false);
//   };

//   return (
//     <div className="landing-container">
//       {isTopAreaVisible && (
//         <div className="show-ad">
//           Grab the latest deal at unbeatable price.
//           {/* <a
//             href="#"
//             onClick={(e) => {
//               e.preventDefault();
//               scrollToPricing();
//             }}
//           >
//             Learn more
//           </a> */}
//         </div>
//       )}
//       <header
//         style={{ top: isTopAreaVisible ? "30px" : "0" }}
//         className={`landing-header ${isScrolled ? "scrolled" : ""}`}
//       >
//         {/* <img
//           src="https://ctzeusbackenduatlogo.blob.core.windows.net/uatlogo/ez-byte-logo.png"
//           alt="Logo"
//           className="app-logo"
//           onClick={handleLogoClick}
//           n
//           style={{
//             cursor: "pointer",
//           }}
//         /> */}

//         <img
//           src={COMPANY_LOGO}
//           alt="Logo"
//           className="app-logo"
//           onClick={handleLogoClick}
//           n
//           style={{
//             cursor: "pointer",
//           }}
//         />

//         <nav className="landing-nav-bar">
//           <a
//             href="#"
//             onClick={(e) => {
//               e.preventDefault();
//               scrollToPricing();
//             }}
//           >
//             Pricing
//           </a>
//           {/* <a href="/login">Login</a> */}

//           <button className="login" onClick={navigationHandeler}>
//             Login
//           </button>
//         </nav>
//       </header>

//       <div className="scrollable-content">
//         <div className="main-content">
//           {/* <img
//             src="https://ctzeusbackenduatlogo.blob.core.windows.net/uatlogo/ez-byte-landing-page-background.jpg"
//             alt="ezebyte"
//             className="background-video"
//           /> */}
//           <img src={EZECOVER} alt="ezebyte" className="background-video" />
//           {/* <video
//             src={myImage}
//             autoPlay
//             loop
//             muted
//             // className="background-video"
//           /> */}
//           <span className="photo-title">
//             <div>Cloud Backup.</div>
//             <div>Photos.</div>
//             <div>Videos.</div>
//             <div>Files.</div>
//             <div>Digital Assets.</div>
//           </span>
//         </div>

//         {pricingData.length !== 0 && (
//           <div id="pricing-section" className="container">
//             <h2 className="plans-heading">Home Plans</h2>

//             <div className="toggle-container">
//               <span
//                 style={
//                   {
//                     // marginLeft: "45px",
//                   }
//                 }
//                 // className={!isAnnual ? "active leading-square" : ""}
//                 className={`leading-square ${!isAnnual ? `active` : ``}`}
//               >
//                 Monthly
//               </span>
//               <div className="toggle-switch" onClick={togglePricing}>
//                 <div
//                   className={`toggle-knob ${isAnnual ? "annual" : "monthly"}`}
//                 />
//               </div>
//               <span className={isAnnual ? "active" : ""}>Yearly</span>
//             </div>

//             <div className="price-row">
//               {pricingData.map((item, index) => (
//                 <div key={index} className="price-col">
//                   <p className="plan-title">{item.plan}</p>
//                   <h3>
//                     {isAnnual ? item.annual : item.monthly}
//                     {/* <span>{isAnnual ? "/ year" : "/ month"}</span> */}

//                     <span className="monthly-yearly">
//                       {item.isBusiness
//                         ? `/ user ${isAnnual ? "/ year" : "/ month"}`
//                         : isAnnual
//                         ? "/ year"
//                         : "/ month"}
//                     </span>
//                   </h3>
//                   <button onClick={() => handleSubscribe(item, isAnnual)}>
//                     Subscribe
//                   </button>
//                   <ul>
//                     {item.features.map((feature, i) => (
//                       <li key={i}>{feature}</li>
//                     ))}
//                     {item.features.length < 4 && <li>&nbsp;</li>}
//                   </ul>
//                   {/* <button>Subscribe</button> */}
//                 </div>
//               ))}
//             </div>
//           </div>
//         )}

//         {pricingDataForBusiness.length !== 0 && (
//           <div id="pricing-section" className="container">
//             <h2 className="plans-heading">Business Plans</h2>

//             <div className="toggle-container">
//               <span
//                 className={`leading-square ${
//                   !isAnnualBusiness ? `active` : ``
//                 }`}
//               >
//                 Monthly
//               </span>
//               <div className="toggle-switch" onClick={togglePricingBusiness}>
//                 <div
//                   className={`toggle-knob ${
//                     isAnnualBusiness ? "annual" : "monthly"
//                   }`}
//                 />
//               </div>
//               <span className={isAnnualBusiness ? "active" : ""}>Yearly</span>
//             </div>

//             <div className="price-row">
//               {pricingDataForBusiness.map((item, index) => (
//                 <div key={index} className="price-col">
//                   <p className="plan-title">{item.plan}</p>
//                   <h3>
//                     {isAnnualBusiness ? item.annual : item.monthly}
//                     {/* <span>{isAnnual ? "/ year" : "/ month"}</span> */}

//                     <span className="monthly-yearly">
//                       {item.isBusiness
//                         ? `/ user ${isAnnualBusiness ? "/ year" : "/ month"}`
//                         : isAnnualBusiness
//                         ? "/ year"
//                         : "/ month"}
//                     </span>
//                   </h3>
//                   <button onClick={() => handleSubscribe(item, isAnnual)}>
//                     Subscribe
//                   </button>
//                   <ul>
//                     {item.features.map((feature, i) => (
//                       <li key={i}>{feature}</li>
//                     ))}
//                     {item.features.length < 4 && <li>&nbsp;</li>}
//                   </ul>
//                 </div>
//               ))}
//             </div>
//           </div>
//         )}

//         {/* <div className="empty-container"></div> */}
//         <footer className="footer">
//           <div className="line"></div>
//           <div className="footer-container">
//             <div className="footer-row">
//               <div className="footer-col two">
//                 <h4>Solutions</h4>
//                 <ul>
//                   <li>
//                     <a href="https://ezebyte.com/solutions/#vendor">
//                       Vendor Integration
//                     </a>
//                   </li>
//                   <li>{/* <a href="#">Locations</a> */}</li>
//                   <li>
//                     <a href="https://ezebyte.com/solutions/#erp">
//                       ERP Integration
//                     </a>
//                   </li>
//                 </ul>
//               </div>

//               <div className="footer-col one " style={{ marginTop: "-2px" }}>
//                 <h4>Products</h4>
//                 <ul>
//                   <li>
//                     <a href="https://ezebyte.com/products/#fraudguard">
//                       Fraud Guard
//                     </a>
//                   </li>
//                   <li>
//                     <a href="https://ezebyte.com/products/#ez-drive">
//                       EZ-Drive
//                     </a>
//                   </li>
//                   <li>
//                     <a href="https://ezebyte.com/products/#ez-share">
//                       EZ-Share
//                     </a>
//                   </li>
//                   <li>
//                     <a href="https://ezebyte.com/products/#ez-eye">EZ-Eye</a>
//                   </li>
//                   <li>
//                     <a href="https://ezebyte.com/products/#ez-schedule">
//                       EZ-Schedule
//                     </a>
//                   </li>
//                   <li>
//                     <a href="https://ezebyte.com/products/#ez-flow">EZ-Flow</a>
//                   </li>
//                   <li>
//                     <a href="https://ezebyte.com/products/#ez-dash">EZ-Dash</a>
//                   </li>
//                 </ul>
//               </div>

//               <div className="footer-col one">
//                 <h4>Get Help</h4>
//                 <ul>
//                   <li>
//                     <a href="#">Support</a>
//                   </li>
//                   <li>
//                     <a href="#">Return Policy</a>
//                   </li>
//                   <li>
//                     <a href="#">Privacy</a>
//                   </li>
//                   <li>
//                     <a href="#">Terms & Conditions</a>
//                   </li>
//                 </ul>
//               </div>

//               <div className="footer-col">
//                 <h4>Company</h4>
//                 <ul>
//                   <li>
//                     <a href="https://ezebyte.com/aboutus/">About Us</a>
//                   </li>
//                   <li>
//                     <a href="https://ezebyte.com/#contact">Contact</a>
//                   </li>
//                 </ul>
//               </div>
//             </div>
//           </div>

//           <div className="footer-bottom">
//             <p>&copy; 2025 EZ-BYTE. All rights reserved.</p>
//           </div>
//         </footer>
//       </div>
//       {showOverlay && (
//         <CheckoutOverlay
//           selectedPlan={selectedPlan}
//           onClose={handleCloseOverlay}
//           dataToSendToStripe={dataToSendToStripe}
//         />
//       )}
//     </div>
//   );
// }

// const CheckoutOverlay = ({ selectedPlan, onClose, dataToSendToStripe }) => {
//   console.log(dataToSendToStripe);

//   const tax = 2.65;
//   const totalAmount = calculateTotalAmount(tax, selectedPlan.price);

//   function calculateTotalAmount(Tax, Price) {
//     const TaxAmount = parseFloat(Tax);
//     const PriceAmount = parseFloat(Price.replace("$", ""));
//     return (TaxAmount + PriceAmount).toFixed(2);
//   }

//   const overlayOutsideClickHandler = (e) => {
//     if (e.target.classList.contains("checkout-overlay")) {
//       onClose();
//     }
//   };

//   const paymentHandler = async () => {
//     const stripe = await loadStripe(
//       "pk_test_51QYRrwP4VPRr08MivQkpNa9EAIEf8Jn4zUvB3H1yM7s8EQUeYmUA28mDwRmNP85b08LQb00OhqIaA5ovPKOlgUif00KWKeDTmE"
//     );

//     const body = {
//       products: dataToSendToStripe,
//     };

//     const headers = {
//       "Content-Type": "application/json",
//     };
//   };

//   return (
//     <div className="checkout-overlay" onClick={overlayOutsideClickHandler}>
//       <div className="checkout-overlay-content">
//         <h2>Checkout</h2>
//         <h3>Purschase Details</h3>

//         <div className="checkout-container">
//           <div className="checkout-summary">
//             <p>
//               <span className="label">
//                 <strong>Plan</strong>:
//               </span>
//               <span className="value">{selectedPlan.planName}</span>
//             </p>
//             <p>
//               <span className="label">
//                 <strong>Tax</strong>:
//               </span>
//               <span className="value">{`$${tax}`}</span>
//             </p>

//             <p>
//               <span className="label">
//                 <strong>Price</strong>:
//               </span>
//               <span className="value">{selectedPlan.price}</span>
//             </p>
//             <div className="total-bill">
//               <p>
//                 <span className="label">
//                   <strong>Amount Payable</strong>:
//                 </span>
//                 <span className="value">{`$${totalAmount}`}</span>
//               </p>
//             </div>
//           </div>

//           <div className="price-col-checkout">
//             <h3>{selectedPlan.planName}</h3>
//             {/* <p>
//               <strong>Price:</strong> {selectedPlan.price}
//             </p> */}
//             <ul>
//               {selectedPlan.description.map((feature, index) => (
//                 <li key={index}>{feature}</li>
//               ))}
//             </ul>
//           </div>
//         </div>
//         <div className="confirm-button">
//           {/* <button className="cancel-button" onClick={onClose}>
//             Cancel
//           </button> */}
//           <button onClick={paymentHandler}>{`Pay $${totalAmount}`}</button>
//         </div>
//       </div>
//     </div>
//   );
// };

// const CheckoutOverlay = ({ selectedPlan, onClose }) => {
//   console.log(selectedPlan.features);
//   return (
//     <div className="checkout-overlay">
//       <div className="overlay-content">
//         <h2>Checkout</h2>
//         <h3>Purschase Details</h3>

//         <div className="checkout-container">
//           <div className="checkout-summary">
//             <p>
//               <span className="label">
//                 <strong>Plan</strong>:
//               </span>
//               <span className="value">{selectedPlan.planName}</span>
//             </p>
//             <p>
//               <span className="label">
//                 <strong>Tax</strong>:
//               </span>
//               <span className="value">$0.00</span>
//             </p>

//             <p>
//               <span className="label">
//                 <strong>Total Price</strong>:
//               </span>
//               <span className="value">{selectedPlan.price}</span>
//             </p>
//           </div>

//           <div className="price-col-checkout">
//             <h3>{selectedPlan.planName}</h3>
//             <p>
//               <strong>Price Chalo:</strong> {selectedPlan.price}
//             </p>
//             <ul>
//               {selectedPlan.description.map((feature, index) => (
//                 <li key={index}>{feature}</li>
//               ))}
//             </ul>
//           </div>
//         </div>

//         <button className="confirm-button">Confirm Purchase</button>
//         <button className="close-button" onClick={onClose}>
//           Close
//         </button>
//       </div>
//     </div>
//   );
// };
